#home-header {
    background-color: #232f3e;
    padding-top: 30px;
    padding-bottom: 30px;
}

#home-header-title {
    color: white;
}

#home-body {
    padding-top: 30px;
    padding-bottom: 30px;
}
