#navbar {
    background-color: #232f3e;
    color: white;
    height: 40px !important;
    display: block;
    margin: 0;
    padding: 0 30px;
    background: #232f3e;
}

#menu-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

#menu-list li {
    padding: 0 4px;
    margin: 10px 0 9px;
}

#menu-list .current {
    color: #ec7211;
}

#menu-list a {
    color: white;
    text-decoration: none;
}

#menu-list a:hover {
    text-decoration: none;
    color: #ec7211;
}

#menu-list #title {
    font-weight: bold;
}

#ml-auto {
    margin-left: auto !important;
}

#links {
    padding-top: .5rem !important;
    margin: 0px !important;
}
