.sandbox-form-text {
    margin-bottom: 25px;
}

#flashbar {
    margin-bottom: 10px;
}

.sandbox-is-stopped {
    border-left: 4px solid #1d8102;
}

.sandbox-is-not-stopped {
    border-left: 4px solid #687078;
}

#sandbox-select-header {
    display: flex;
}

#refresh-button {
    float: right;
    margin-left: auto;
}

#update-button {
    float: right;
    margin-left:auto;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}
